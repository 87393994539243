import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "emptyParagraph",
  standalone: true,
})
export class EmptyParagraphPipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): unknown {
    if (!value) {
      return "";
    }

    const transforms = value.split(/<p>\s*<\/p>/).join("<p>&nbsp;</p>");

    return transforms;
  }
}
