import { Pipe, PipeTransform } from "@angular/core";
import { InfiniteData } from "@ngneat/query";

@Pipe({
  name: "queryListCount",
  standalone: true,
  pure: true,
})
export class QueryListCountPipe implements PipeTransform {
  transform(
    value?: InfiniteData<any, unknown>,
    ...args: unknown[]
  ): { total: number; current: number; size: number; pageIndex: number } {
    const [fk1] = args;
    const key = (fk1 as string) ?? "";
    let total = 0;
    let current = 0;
    let size = 0;
    let pageIndex = 1;

    if (!value) {
      return { total, current, size, pageIndex };
    }

    const { pages } = value;
    if (!pages) {
      return { total, current, size, pageIndex };
    }

    for (const page of pages) {
      if (typeof page === "undefined") continue;
      total = page?.count ? page.count : 0;
      size = page?.limit ? page.limit : 0;
      const skip = page.skip ? page.skip : 0;
      pageIndex = skip + 1;
      current += page && page[key] ? page[key].length : 0;
    }

    return { total, current, size, pageIndex };
  }
}
